<template>
  <div class="dropdown"
       ref="dropdown" v-if="this.showLocationTooltip">
    <div class="title">
      {{ textTitle }} <strong>{{ date }}</strong>
      <br>
    </div>

    <div class="no-snapshots" v-if="item.snapshots[0] === 1">
      We don't have any data for this date
    </div>

    <div v-else>
      <strong>{{ textMonitorUptime }}:</strong> {{ (item.uptime * 100).toFixed(2) }} %
      <table class="table">
        <thead>
        <tr>
          <th>{{ textLocation}}</th>
          <th>{{ textUptime }}</th>
          <th>{{ textDowntime }}</th>
          <th>{{ textLatency }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in item.snapshots" :key="item.node.id">
          <td>
            <node-name :node="item.node"/>
          </td>
          <td>
            {{ item.uptime | uptime }}
          </td>
          <td>
            {{ getDowntime(item) }}
          </td>
          <td>
            {{ item.time_total | responseTime }} sec
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div v-else>
    <strong class="title">{{ date }}</strong>
    <br>
    <strong>{{ textMonitorUptime }}:</strong> {{ (item.uptime * 100).toFixed(2) }} %
  </div>
</template>

<script>
import NodeName from '@/components/Node/NodeName.vue'

import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
export default {
  components: {
    NodeName
  },

  props: {
    item: {
      required: true,
      type: Object
    },
    statusPageLanguage: {
      required: true,
      type: Object
    },
    userTimeZone: {
      required: false,
      type: String
    },
    monitorCreatedAt: {
      required: true,
      type: String
    },
    showLocationTooltip: {
      required: false,
      type: Number,
      default: 1
    }
  },

  created () {
    dayjs.extend(customParseFormat)
    dayjs.extend(timezone)
    dayjs.extend(utc)
  },

  methods: {
    getDowntime (item) {
      // itemDate reversed to match today date format
      let today = new Date().toJSON().slice(0, 10)
      let itemDate = item.date.split('-').reverse().join('-')
      let monitorCreationDate = new Date(this.monitorCreatedAt.slice(0, -1))
      let currentDateAndTime = new Date(item.node.updated_at.slice(0, -1))
      let startOfCurrentDay = new Date(currentDateAndTime.getTime())
      startOfCurrentDay.setHours(0, 0, 0, 0)

      let elapsedNumberOfSeconds
      if (today > itemDate) {
        // for items that are in the past we set the elapsed seconds to a full day
        elapsedNumberOfSeconds = 86400 // number of seconds in a full day
      } else {
        // for items that are created in the current day, we set the elapsed seconds
        // to the difference between the current time and the start of the day/monitor creation date
        if (startOfCurrentDay > monitorCreationDate) {
          elapsedNumberOfSeconds = (currentDateAndTime - startOfCurrentDay) / 1000
        } else {
          elapsedNumberOfSeconds = (currentDateAndTime - monitorCreationDate) / 1000
        }
      }

      if (!item.uptime) {
        return null
      }

      if (item.uptime === 1) {
        return '0 seconds'
      }

      let secondsDowntime = elapsedNumberOfSeconds * (1 - item.uptime)

      let secondsNumber = Math.floor(secondsDowntime % 60)
      let minutesNumber = Math.floor(secondsDowntime / 60)

      let minutes = minutesNumber === 1 ? 'minute' : 'minutes'
      let seconds = secondsNumber === 1 ? 'second' : 'seconds'

      if (minutesNumber && secondsNumber) {
        return `${minutesNumber} ${minutes} ${secondsNumber} ${seconds}`
      }

      if (minutesNumber) {
        return `${minutesNumber} ${minutes}`
      }

      if (secondsNumber) {
        return `${secondsNumber} ${seconds}`
      }

      return null
    }
  },

  computed: {
    date () {
      if (this.userTimeZone) {
        return dayjs(this.item.date, 'DD-MM-YYYY').tz(this.userTimeZone).format('D MMM YYYY')
      } else {
        return dayjs(this.item.date, 'DD-MM-YYYY').format('D MMM YYYY')
      }
    },
    textTitle () {
      if (this.statusPageLanguage.text_popup_incident_text) {
        return this.statusPageLanguage.text_popup_incident_text
      }

      return 'Breakdown of uptime by locations on'
    },
    textLocation () {
      if (this.statusPageLanguage.text_popup_incident_location) {
        return this.statusPageLanguage.text_popup_incident_location
      }

      return 'Location'
    },
    textUptime () {
      if (this.statusPageLanguage.text_popup_incident_uptime) {
        return this.statusPageLanguage.text_popup_incident_uptime
      }

      return 'Uptime'
    },
    textDowntime () {
      if (this.statusPageLanguage.text_popup_incident_downtime) {
        return this.statusPageLanguage.text_popup_incident_downtime
      }

      return 'Downtime'
    },
    textLatency () {
      if (this.statusPageLanguage.text_popup_incident_latency) {
        return this.statusPageLanguage.text_popup_incident_latency
      }

      return 'Latency'
    },

    textMonitorUptime () {
      return this.statusPageLanguage.text_monitor_uptime ?? 'Monitor Uptime'
    }
  }
}
</script>

<style lang="scss">
  .dropdown {
    padding: 10px;
    min-width: 500px;
    max-height: 480px;
    overflow: scroll;

    & > .title {
      margin-bottom: 10px;

      font-size: 14px;
      line-height: 20px;
      color: #888799;
    }

    .table {
      padding: 0;
    }

    .no-snapshots {
      padding: 10px;
      border-radius: 4px;
      background-color: #F7F9FC;
    }
  }
  .title {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px;
    color: #888799;
  }
</style>
